import { useQuery } from '@apollo/client';
import { useInView } from 'react-intersection-observer';
import { useLocation } from 'react-router';
import { Navigate } from '@flame-frontend-utils/commons-router6';
import { Page } from '../../components/Page';
import { Content } from './components';
import { NotFoundPage } from '../NotFoundPage';
import { LoadingPage } from '../LoadingPage';
import { MediaPostDocument } from './gql/MediaPost.document';
import { LinkedData } from './components/LinkedData';
import { PostSpecies } from '../../lib/graphql.document';
import { getOgImageUrl } from '../../components/CoverImage';
import { AdvertisementDisabledProvider, AdvertisementViewReporter } from '../../components/Advertisement';
import { ROUTES } from '../../lib/ROUTES';
import { useUserSpecificDataLoaded } from '../../components/ApolloProvider';
import { PromotedContent } from './components/PromotedContent';
import { tw } from '../../styles/tw';
import { PostsList } from './components/PostsList';
import { appendSearchParams } from '../../lib/appendSearchParams';
import { overrideParams } from '../../lib/overrideParams';

interface PostPageContentProps {
  slug: string;
}

const PostPageContent = ({ slug }: PostPageContentProps): JSX.Element => {
  const { data: currentData, previousData } = useQuery(MediaPostDocument, {
    variables: { slug },
  });

  const data = currentData || previousData;

  const mediaPost = data?.mediaPost;
  const isBlog = mediaPost?.species === PostSpecies.Blog;

  const userSpecificDataLoaded = useUserSpecificDataLoaded();

  const location = useLocation();

  const { ref: intersectionObserverRef, inView } = useInView({ triggerOnce: true, rootMargin: '200px' });

  if (mediaPost) {
    return location.pathname === ROUTES.POST.$buildPath({ params: { slug: mediaPost.slug } }) &&
      hasAllParams(location.search, mediaPost.searchParams) ? (
      <AdvertisementViewReporter postId={mediaPost.id}>
        <Page
          className={tw('mb-5 flex flex-col items-stretch justify-center')}
          hideAdvertisement={!mediaPost.isAdvertisementEnabled || mediaPost.disabledAdvertisements}
          documentTitle={mediaPost.title}
          documentDescription={mediaPost.description || (isBlog ? PUBLIC_CONFIG.BLOGS_DESCRIPTION : '')}
          ogImage={getOgImageUrl(mediaPost.cover)}
          ogType="article"
          canonicalUrl={ROUTES.POST.$buildPath({ params: { slug: mediaPost.slug } })}
          twitterCard="summary_large_image"
          robotsMeta="max-image-preview:large"
          includeRssFeedLink={!isBlog}
        >
          <LinkedData post={mediaPost} />

          <AdvertisementDisabledProvider
            key={mediaPost.id}
            disabled={!mediaPost.isAdvertisementEnabled}
            disabledAds={mediaPost.disabledAdvertisements}
          >
            <Content
              mediaPost={mediaPost}
              observedByVerstka={Boolean(mediaPost.promotedPost && mediaPost.promotedPost.id !== mediaPost.id)}
            />
          </AdvertisementDisabledProvider>

          {mediaPost.promotedPost && mediaPost.promotedPost.id !== mediaPost.id ? (
            <AdvertisementDisabledProvider
              key={mediaPost.promotedPost.id}
              disabled={!mediaPost.promotedPost.isAdvertisementEnabled}
              disabledAds={mediaPost.promotedPost.disabledAdvertisements}
            >
              <PromotedContent mediaPost={mediaPost.promotedPost} parentPost={mediaPost} />
            </AdvertisementDisabledProvider>
          ) : null}

          {!inView ? <div ref={intersectionObserverRef} /> : <PostsList isBlog={isBlog} postId={mediaPost.id} />}
        </Page>
      </AdvertisementViewReporter>
    ) : (
      <Navigate
        to={appendSearchParams(
          ROUTES.POST.$buildPath({ params: { slug: mediaPost.slug } }),
          overrideParams(location.search, mediaPost.searchParams)
        )}
        replace
        statusCode={301}
      />
    );
  }

  if (data) {
    return userSpecificDataLoaded ? <NotFoundPage /> : <LoadingPage statusCode={404} />;
  }

  return <LoadingPage />;
};

function hasAllParams(target: string, source: string): boolean {
  const targetParams = new URLSearchParams(target);
  const sourceParams = new URLSearchParams(source);

  // eslint-disable-next-line no-restricted-syntax
  for (const key of sourceParams.keys()) {
    const targetValues = targetParams.getAll(key);
    const sourceValues = sourceParams.getAll(key);

    if (targetValues.length !== sourceValues.length) {
      return false;
    }

    if (sourceValues.findIndex((value, index) => targetValues[index] !== value) !== -1) {
      return false;
    }
  }

  return true;
}

export { PostPageContent };
